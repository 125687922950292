<template>
  <div class="dialog-warpper">
    <el-dialog
      append-to-body
      title="历史记录"
      :visible.sync="visible"
      :before-close="handleClose"
      class="dialog-warpper"
    >
      <div class="table-container">
        <el-table
          v-loading="tableLoading"
          border
          height="550px"
          class="table-class"
          :data="finalData"
          :header-cell-style="{ borderRight: '1px solid #ebeef5' }"
        >
          <el-table-column fixed prop="modifyTime" label="编辑时间" width="200">
            <template slot-scope="{ row }">
              {{ parseTime(row.modifyTime) }}
            </template>
          </el-table-column>
          <el-table-column v-for="(item, index) in tableHead" :key="index" :label="item.level" :prop="item.levelName">
            <template slot-scope="{ row }">
              <div v-if="row[item.levelName]">
                {{ row[item.levelName] }}
              </div>
              <div v-else>未编辑</div>
            </template>
          </el-table-column>
        </el-table>
        <!-- <div class="tc" v-if="tableHead.length === 0">暂无数据</div> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { parseTime } from '@/utils'
import { getHistory, getVipList } from '@/api/vipLevel'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      finalData: [],
      tableData: [],
      levelList: [],
      tableLoading: true
    }
  },
  async created() {
    const page = {
      pageIndex: 1,
      pageSize: 0
    }
    try {
      this.tableLoading = true
      const { code: code1, detail: detail1 } = await getVipList(page)
      const { code: code2, detail: detail2 } = await getHistory(page)
      if (code1 == 0 && code2 == 0) {
        this.levelList = detail1
        this.tableData = detail2
        this.finalData = this.getFinalData(this.tableData).sort((a, b) => {
          return +new Date(b.modifyTime) - +new Date(a.modifyTime)
        })
      }
    } finally {
      this.tableLoading = false
    }
  },
  methods: {
    parseTime,
    handleClose() {
      this.$emit('update:visible', false)
    },
    getFinalData(data) {
      return (data || []).map((row) => {
        let tempObj = {}
        const transferArr = JSON.parse(row.previousLevelDetail)
        tempObj[transferArr.levelName] = transferArr.priceDiscount
        return Object.assign(row, tempObj)
      })
    }
  },
  computed: {
    tableHead() {
      if (!this.levelList.length) return []
      return this.levelList.map(({ levelName, level }) => ({ levelName, level: level + '折扣' }))
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-warpper {
  ::v-deep {
    .el-dialog__title {
      font-size: 14px;
      color: #1a1a1a;
    }
    .el-dialog__header {
      height: 52px;
      line-height: 52px;
      padding: 0 0 0 24px;
      border: 1px solid #dcdee0;
    }
  }
}
.table-container {
  max-height: 600px;
  overflow-y: auto;
  .tc {
    text-align: center;
  }
  .table-class {
    border-left: 1px solid #ebeef5;
    border-top: 1px solid #ebeef5;
  }
}
</style>
